import cn from 'classnames';
import * as React from 'react';
import {RefObject} from 'react';
import ReactTextareaAutosize, {TextareaAutosizeProps} from 'react-textarea-autosize';

import s from './FieldInline.module.scss';

type AutoWidthConfig = {
  minWidth: number;
  maxWidth: number;
};

type FieldInlineProps<T extends 'textarea' | 'input'> = {
  className?: string;
  type?: 'text' | 'email' | 'tel' | 'number';
  sizeClass?: 's' | 'm' | 'l';
  element?: T;
  bgcolor?: 'second';
  disabled?: boolean;
  autoGrowth?: AutoWidthConfig;
  inputRef?: RefObject<HTMLInputElement>;
  pretext?: string;
  isJapanese?: boolean;
} & (T extends 'textarea' ? TextareaAutosizeProps : React.InputHTMLAttributes<HTMLInputElement>);

const FieldInline = <T extends 'textarea' | 'input'>({
  className,
  disabled,
  type = 'text',
  placeholder = 'Enter text',
  element = 'input' as T,
  bgcolor,
  sizeClass = 'm',
  autoGrowth = null,
  inputRef,
  pretext = '',
  isJapanese = false,
  ...props
}: FieldInlineProps<T>) => {
  const isTextarea = element === 'textarea';
  const classesList = cn(
    s.fieldInline,
    isTextarea && s.fieldInline_textarea,
    sizeClass && s[`fieldInline_size_${sizeClass}`],
    bgcolor && s[`fieldInline_bgcolor_${bgcolor}`],
    className,
  );

  const pretextClass = cn({[s.fieldInline__pretext]: !!pretext});

  if (isTextarea) {
    return (
      <ReactTextareaAutosize
        className={classesList}
        placeholder={placeholder}
        disabled={disabled}
        {...(props as Partial<TextareaAutosizeProps>)}
      />
    );
  }

  const inputValue = props.value?.toString();

  return (
    <label htmlFor="input-text" className={pretextClass}>
      {!isJapanese && pretext}
      <input
        id="input-text"
        style={
          !!autoGrowth
            ? {
                width: Math.min(Math.max(inputValue.length, autoGrowth.minWidth / 16), autoGrowth.maxWidth / 16) + 'ch',
              }
            : {}
        }
        className={classesList}
        disabled={disabled}
        type={type}
        ref={inputRef}
        placeholder={placeholder}
        {...(props as Partial<React.InputHTMLAttributes<HTMLInputElement>>)}
      />
      {isJapanese && pretext}
    </label>
  );
};

export default FieldInline;
