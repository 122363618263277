import ProjectsApi from 'api/projects';
import {normalizeFieldName} from 'shared/components/TasksImport/utils/functions';
import {TaskImportProjectCustomFieldDef} from 'shared/components/TasksImport/utils/types';
import {ProjectCustomFieldType, ProjectCustomFieldDef} from 'shared/models/project';

type CustomFieldFail = {
  reason: any;
  data: ProjectCustomFieldDef;
};

// Handle API calls for custom fields
export const processCustomFieldsApi = async (
  customFields: TaskImportProjectCustomFieldDef[],
  projectId: string,
  existingCustomFields: ProjectCustomFieldDef[],
) => {
  const existingFieldsMap = new Map(existingCustomFields.map((field) => [field.internalFieldName, field]));

  const updated: ProjectCustomFieldDef[] = [];
  const created: ProjectCustomFieldDef[] = [];
  const failed: CustomFieldFail[] = [];

  for (const field of customFields) {
    try {
      const supportFieldData = [ProjectCustomFieldType.select, ProjectCustomFieldType.multiselect].includes(
        field.fieldType,
      );

      let processedFieldData = '';
      if (supportFieldData && field.fieldData) {
        try {
          const parsed = JSON.parse(field.fieldData.trim());
          processedFieldData = JSON.stringify(parsed);
        } catch {
          processedFieldData = JSON.stringify(
            field.fieldData
              .trim()
              .split('\n')
              .filter((line) => line !== ''),
          );
        }
      }

      const params = {
        fieldType: field.fieldType,
        fieldName: normalizeFieldName(field.fieldName),
        fieldData: processedFieldData,
        internalFieldName: field.internalFieldName ?? undefined,
      };

      // Handle new fields
      if (!field.internalFieldName) {
        try {
          const data = await ProjectsApi.createCustomFieldDef(projectId, params);
          created.push(data);
        } catch (error) {
          failed.push({
            reason: error,
            data: params,
          });
        }
      }
      // Handle existing fields
      else {
        const existingField = existingFieldsMap.get(field.internalFieldName);
        if (existingField && existingField.fieldName !== params.fieldName) {
          try {
            const okay = await ProjectsApi.updateCustomFieldDef(projectId, params);
            if (okay) {
              updated.push(params);
            } else {
              failed.push({
                reason: 'Update failed',
                data: params,
              });
            }
          } catch (error) {
            failed.push({
              reason: error,
              data: params,
            });
          }
        } else {
          updated.push(params);
        }
      }
    } catch (error) {
      failed.push({
        reason: error,
        data: field,
      });
    }
  }

  return {updated, created, failed};
};

// Handle import settings updates
export const updateImportSettings = (
  existingSettings: string,
  customFields: TaskImportProjectCustomFieldDef[],
  updated: ProjectCustomFieldDef[],
  created: ProjectCustomFieldDef[],
) => {
  const settings = JSON.parse(existingSettings || '{"mapping":{}, "customFieldsMapping":{}}');

  const customFieldsMapping = Object.fromEntries(
    customFields.map((field) => {
      const normalizedFieldName = normalizeFieldName(field.fieldName);
      const matchingField = [...updated, ...created].find((cfRes) => cfRes.fieldName === normalizedFieldName);
      return [matchingField.internalFieldName, field.mappedTo];
    }),
  );

  return JSON.stringify({
    ...settings,
    customFieldsMapping,
  });
};
