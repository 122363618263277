import * as Sentry from '@sentry/browser';
import {GanttStatic} from 'dhtmlx-gantt';
import {ChangeEvent, FC, ReactNode, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import ProjectsApi from 'api/projects';
import {useGanttColumns} from 'modules/Tasks/components/Gantt/hooks/useGanttColumns';
import {GANTT_COLUMNS_NAMES} from 'modules/Tasks/components/Gantt/utils/constants';
import {resetFrozenColumnsOffset} from 'modules/Tasks/components/Gantt/utils/functions';
import {useViewMode} from 'modules/Tasks/hooks/useViewMode';
import {useConfirm} from 'shared/components/Confirmation';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import CtrlCheckOption from 'shared/components/CoreNewUI/CtrlCheckOption/CtrlCheckOption';
import CtrlDrop from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import {TasksViewMode} from 'shared/constants/common';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useProject} from 'shared/hooks/useProject';
import {useRootDispatch} from 'store';
import {projectActions} from 'store/projects';

import s from './GanttColumnsDropdown.module.scss';
import ProjectCustomColumnPopup from './ProjectCustomColumnPopup';
import popupStyles from './ProjectCustomColumnPopup/ProjectCustomColumnPopup.module.scss';

type Props = {
  gantt: GanttStatic;
  button?: ReactNode;
};

const DISABLED_COLUMNS = [GANTT_COLUMNS_NAMES.name];

const GanttColumnsDropdown: FC<Props> = ({gantt, button}: Props) => {
  const {projectId} = useParams<{projectId: string}>();
  const {project} = useProject(projectId);
  const {t} = useTranslation(['gantt', 'project']);
  const [viewMode] = useViewMode(projectId);

  const {addNewCustomColumn, columns, hiddenColumnsList, removeCustomColumn, updateColumnConfig} = useGanttColumns(
    gantt,
    projectId,
  );
  const {mixpanel} = useAnalyticsService();

  const {confirm} = useConfirm();
  const dispatch = useRootDispatch();
  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);
  const [showCustomFieldPopup, setShowCustomFieldPopup] = useState({show: false, fieldName: null});
  const isIssueViewMode = viewMode === TasksViewMode.issues;

  const togglePopup = (fieldName = '') => setShowCustomFieldPopup((prev) => ({show: !prev.show, fieldName}));

  const onSelect = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const {
        name,
        checked,
        dataset: {label},
      } = e.currentTarget;
      const {columnTemplateCheck, columnTemplateUncheck} = mixpanel.events.gantt;
      const eventName = (checked ? columnTemplateCheck : columnTemplateUncheck) + label.replaceAll(' ', '');
      mixpanel.track(eventName);

      updateColumnConfig(name as GANTT_COLUMNS_NAMES, !checked);

      if (gantt) {
        const column = gantt.config.columns.find((col) => col.name === name);
        if (column) {
          column.hide = !checked;
          gantt.dRender();
        }
        resetFrozenColumnsOffset();
      }
    },
    [gantt, mixpanel, updateColumnConfig],
  );
  const ganttColumnsLabels = gantt.getColumnLabels?.(t) ?? {};

  const deleteCustomColumn = async (fieldName: string, internalFieldName: string) => {
    try {
      const confirmConfig = {
        description: t('project:confirm.delete_custom_column.description', {
          columnName: fieldName,
        }),
        title: t('project:confirm.delete_custom_column.title'),
        acceptButton: t('project:confirm.delete_custom_column.acceptButton'),
      };
      if (await confirm(confirmConfig)) {
        await ProjectsApi.deleteCustomFieldDef(projectId, internalFieldName);
        dispatch(
          projectActions.updateProject({
            id: projectId,
            changes: {
              customFieldDef: project.customFieldDef.filter((col) => col.internalFieldName !== internalFieldName),
            },
          }),
        );
        removeCustomColumn(internalFieldName);
      }
    } catch (e) {
      Sentry.captureException(e);
      if (isAxiosError(e)) toast.error(extractAxiosError(e));
    }
  };

  return (
    <>
      <ProjectCustomColumnPopup
        projectId={projectId}
        visible={showCustomFieldPopup.show}
        internalFieldName={showCustomFieldPopup.fieldName}
        onClose={togglePopup}
        onAfterCreate={addNewCustomColumn}
      />
      <CtrlDrop toggleElement={button} maxMenuHeight={400} excludeClassOutsideClick={[popupStyles.creationPopup]}>
        {!isIssueViewMode && hasAnyAdminRole && (
          <CtrlButton
            style={{width: '100%', marginBottom: '5px'}}
            color="second"
            icon="add-circle-outlined"
            onClick={() => togglePopup()}
          >
            Add Column
          </CtrlButton>
        )}
        {!!columns.length &&
          columns
            .filter(({isCustom}) => {
              return !isCustom || (isCustom && !isIssueViewMode);
            })
            .map(({name, isCustom, label}, i) => {
              return (
                <div key={`${i}_${name}`} className={s.ganttColumnsDropdown__box}>
                  <CtrlCheckOption label={isCustom ? label : ganttColumnsLabels[name]}>
                    <input
                      key={`${i}_${name}_input`}
                      id={`col__${name}`}
                      data-label={isCustom ? label : ganttColumnsLabels[name]}
                      className="ctrl-check__field"
                      type="checkbox"
                      name={name}
                      disabled={DISABLED_COLUMNS.includes(name as GANTT_COLUMNS_NAMES)}
                      value={name}
                      checked={!hiddenColumnsList?.includes(name as GANTT_COLUMNS_NAMES)}
                      onChange={onSelect}
                    />
                  </CtrlCheckOption>
                  {isCustom && hasAnyAdminRole ? (
                    <>
                      <CtrlButton iconOnly size="xs" icon="edit" color="clear" onClick={() => togglePopup(name)} />
                      <CtrlButton
                        iconOnly
                        size="xs"
                        icon="remove_from_trash"
                        color="clear"
                        onClick={() => deleteCustomColumn(label, name)}
                      />
                    </>
                  ) : null}
                </div>
              );
            })}
      </CtrlDrop>
    </>
  );
};

export default GanttColumnsDropdown;
