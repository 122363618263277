import equal from 'fast-deep-equal';
import {Formik, FormikProps} from 'formik';
import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import Autocomplete from 'shared/components/CoreNewUI/Autocomplete/Autocomplete';
import CtrlBtnOption from 'shared/components/CoreNewUI/CtrlBtnOption/CtrlBtnOption';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import Dropdown from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import Icon from 'shared/components/Icon';
import {IconsMap} from 'shared/constants/icons';
import {RouteParams} from 'shared/constants/routes';
import {useDebounce} from 'shared/hooks/core/useDebounce';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useTasksUrlState} from 'shared/hooks/useTasksUrlState';
import {TaskFilterQuery} from 'shared/models/task/filter';

import styles from '../ActionsBar/components/FilterDropdown/FilterDropdown.module.scss';
import {useProjectSubcontractorOptions} from '../ActionsBar/components/FilterDropdown/useProjectSubcontractorOptions';
import {useResponsibleOptions} from '../ActionsBar/components/FilterDropdown/useResponsibleOptions';
import {WBSAutocomplete} from '../ActionsBar/components/FilterDropdown/WBSAutocomplete';
import SearchField from '../ActionsBar/components/SearchField/SearchField';
import {useFilterContext} from '../Filters/FilterProvider';

type DailiesFilterDropdownProps = {
  className?: string;
  active?: boolean;
  sideContentDirection?: 'left' | 'right';
};

const DailiesFilterDropdown = ({className, active, sideContentDirection = 'left'}: DailiesFilterDropdownProps) => {
  const tasksState = useTasksUrlState();
  const {projectId} = useParams<RouteParams['tasks']>();
  const {t} = useTranslation(['filters', 'gantt']);
  const {updateSearchParams, reset, queryParams, taskState, filtersState} = useFilterContext();
  const {viewMode} = useFilterContext();
  const {isProjectAdmin} = useCompanyWorkerRoles(projectId);
  const responsibleOptions = useResponsibleOptions(projectId);
  const {data: subcontractorOptions, isLoading: isLoadingSubcontractors} = useProjectSubcontractorOptions(projectId);

  const {
    mixpanel: {events, ...mixpanel},
  } = useAnalyticsService({extraMeta: {projectId, isReadOnlyMode: !isProjectAdmin, viewMode}});
  const formik = useRef<FormikProps<TaskFilterQuery>>(null);

  const syncFormikStateWithQuery = useDebounce(() => {
    if (!equal(formik.current?.values, queryParams)) {
      formik.current?.setValues(queryParams);
    }
  }, 500);

  useEffect(() => {
    syncFormikStateWithQuery();
  }, [queryParams]);

  const onSearchInputChange = (q: string) => {
    updateSearchParams({...queryParams, q});
  };

  const applyFilters = (values: TaskFilterQuery) => {
    if (!equal(queryParams, values)) {
      updateSearchParams({...queryParams, ...values});
    }
  };

  return (
    <Dropdown
      viewportPosition="right"
      className={className}
      toggleElement={
        <SearchField
          name="filterSearch"
          onChange={onSearchInputChange}
          value={queryParams.q}
          active={active}
          className={styles.filterDropdown__input}
          inputClassName={styles.filterDropdown__input_size}
        />
      }
      toggleElementId="toolbarFiltersToggle"
      header={{
        title: t('filters:dropdown.header', 'Filters'),
        button: {
          title: t('filters:dropdown.reset', 'Reset all'),
          onClick: () => reset({exclude: ['q', 'schedWeeks', 'schedEndFirst', 'showPastDue']}),
        },
      }}
    >
      <Formik<TaskFilterQuery>
        innerRef={formik}
        onSubmit={applyFilters}
        initialValues={filtersState.current[viewMode][taskState]}
      >
        {({handleSubmit, values, setFieldValue}) => {
          return (
            <>
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.responsible', 'Responsible')}
                countSelected={values.responsible ? 1 : 0}
                iconRight={
                  values.responsible ? (
                    <Icon name={IconsMap.clear} colorFill onClick={() => setFieldValue('responsible', null)} />
                  ) : null
                }
                icon={<Icon colorFill name={IconsMap.assign} />}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    items={responsibleOptions}
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(
                        () => setFieldValue(name, value),
                        events.gantt.filterDropdown.responsible,
                      )
                    }
                    selected={values.responsible}
                    name="responsible"
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.subcontractor', 'Subcontractor')}
                countSelected={values.responsibleOrgIds.length}
                icon={<Icon colorFill name={IconsMap.subcontractor} />}
                iconRight={values.responsibleOrgIds.length ? <Icon name="clear" colorFill /> : null}
                onClick={() => setFieldValue('responsibleOrgIds', [])}
                nestedContentPlacement={sideContentDirection}
                nested={
                  <Autocomplete
                    isMulti
                    items={subcontractorOptions}
                    name="responsibleOrgIds"
                    onSelect={(name, value) =>
                      mixpanel.trackWithAction(
                        () => setFieldValue(name, value),
                        events.gantt.filterDropdown.subcontractor,
                      )
                    }
                    selected={values.responsibleOrgIds}
                    disabled={isLoadingSubcontractors}
                  />
                }
              />
              <CtrlBtnOption
                className={styles.filterDropdown__buttonOption}
                openOnHover
                title={t('filters:tasks.options.wbs', 'WBS')}
                countSelected={values.outlineSortKeyPrefixList.length}
                icon={<Icon colorFill name={IconsMap.parenttask} />}
                iconRight={
                  values.outlineSortKeyPrefixList.length ? (
                    <Icon name="clear" colorFill onClick={() => setFieldValue('outlineSortKeyPrefixList', [])} />
                  ) : null
                }
                nestedContentPlacement={sideContentDirection}
                nested={
                  <WBSAutocomplete
                    projectId={projectId}
                    taskState={tasksState}
                    isMulti
                    onSelect={setFieldValue}
                    selected={values.outlineSortKeyPrefixList}
                    name="outlineSortKeyPrefixList"
                  />
                }
              />
              <CtrlButton
                style={{width: '100%', marginTop: '15px'}}
                type="submit"
                onClick={() => mixpanel.trackWithAction(handleSubmit, events.gantt.filterDropdown.applyButton)}
              >
                {t('filters:dropdown.apply', 'Apply')}
              </CtrlButton>
            </>
          );
        }}
      </Formik>
    </Dropdown>
  );
};

export default DailiesFilterDropdown;
