import {FC} from 'react';
import {createPortal} from 'react-dom';

import NewConfirmation from 'shared/components/Confirmation/NewConfirmation';

import Confirmation, {ConfirmationProps} from './Confirmation';

type Props = {
  visible: boolean;
  onClose: () => void;
  useNewLayout?: boolean;
} & ConfirmationProps;

const ConfirmationPopup: FC<Props> = ({
  visible,
  onClose,
  onAccept,
  onReject,
  useNewLayout = false,
  ...confirmProps
}) => {
  const container = document.body;
  if (!visible || !container) return null;
  const actionWithClose = (cb?: () => void) => () => {
    onClose?.();
    cb?.();
  };

  const ConfirmationComponent = useNewLayout ? NewConfirmation : Confirmation;

  return createPortal(
    <div
      className="popup-confirmation-container"
      onClick={(e) => e.stopPropagation()}
      onMouseDown={(e) => e.stopPropagation()}
      data-popup-role="confirmation"
    >
      <ConfirmationComponent
        {...confirmProps}
        onClose={onClose}
        onAccept={onAccept && actionWithClose(onAccept)}
        onReject={onReject && actionWithClose(onReject)}
      />
    </div>,
    container,
  );
};
export default ConfirmationPopup;
