import * as Sentry from '@sentry/react';
import {GanttStatic} from 'dhtmlx-gantt';

import {SafeParseResult} from 'modules/Tasks/types/safeParse';
import {ObserverAction, ObserverActionSource} from 'services/TasksObserver/const';
import {TasksObserver} from 'services/TasksObserver/TasksObserver';
import {ProjectModel} from 'shared/models/project';
import {TaskProjection} from 'shared/models/task/const';
import {TaskDetailsModelDTO} from 'shared/models/task/task';

export const batchUpdateSelectedTasks = (
  gantt: GanttStatic,
  data: PromiseFulfilledResult<TaskDetailsModelDTO>[],
  project: ProjectModel,
  observer: TasksObserver,
) => {
  gantt.batchUpdate(function () {
    gantt.eachSelectedTask(function (taskId: string) {
      const updatedTask = data.find((promise) => promise.value.id === taskId)?.value;
      if (updatedTask) {
        updateTasksCache(observer, updatedTask);
      }
    });
  });
};

const updateTasksCache = (observer: TasksObserver, task: TaskDetailsModelDTO) => {
  observer.emit([{data: task}], {
    action: ObserverAction.update,
    projectId: task.projectId,
    projection: TaskProjection.taskDetail,
    source: ObserverActionSource.bulk,
  });
};

export function safeParseJSON<T>(json: string): SafeParseResult<T> {
  try {
    const parsed = JSON.parse(json);
    return {
      success: true,
      data: parsed as T,
    };
  } catch (error) {
    Sentry.captureException(error);
    return {
      success: false,
      error: error instanceof Error ? error : new Error('Failed to parse JSON'),
    };
  }
}
