import {AxiosResponse} from 'axios';
import {TFunction} from 'i18next';
import {generatePath} from 'react-router';

import {ApiResponseDataRange} from 'shared/models/common';

const toCamel = (s) => {
  return s
    ? s.replace(/([-_][a-z])/gi, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
      })
    : s;
};

function toSnake(string) {
  return string
    ? string
        .replace(/[\w]([A-Z])/g, function (m) {
          return m[0] + '_' + m[1];
        })
        .toLowerCase()
    : string;
}

export function toTitleCase(value: string, keepNotation = false) {
  if (!value) return null;
  return value.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + (keepNotation ? txt.substr(1) : txt.substr(1).toLowerCase());
  });
}

const isArray = function (a) {
  return Array.isArray(a);
};

export const isObject = function (o): o is Record<string, unknown> {
  return o === Object(o) && !isArray(o) && typeof o !== 'function' && o !== null;
};

export const isOEmptyObject = function (o) {
  return isObject(o) && Object.keys(o).length === 0;
};

// Move element inside array, returns new array copy
export const moveArrayElement = function <T>(array: T[], from: number, to: number) {
  const newArray = [...array];
  newArray.splice(to, 0, newArray.splice(from, 1)[0]);
  return newArray;
};

/**
  @param {string} toStyle - Can be one of 2 values: 'camel' or 'snake'
  @param {string|object} o
  @return {object} o
 */
export const convertKeys = function (toStyle: 'snake' | 'camel', o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[toStyle === 'snake' ? toSnake(k) : toCamel(k)] = convertKeys(toStyle, o[k]);
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i) => {
      return convertKeys(toStyle, i);
    });
  }

  return o;
};

export const clearEmptinessFromObject = <T>(payload: T) => {
  const res = {...payload};
  if (isObject(res) && !isOEmptyObject(res)) {
    for (const key of Object.keys(res)) {
      if (res[key] == null || res[key] === '' || (Array.isArray(res[key]) && (<any>res[key]).length === 0)) {
        delete res[key];
      }
    }
  }
  return res as Partial<T>;
};

export function getTimestampString(dateObj: Date) {
  return dateObj.getTime().toString();
}

export const deleteNullOrEmptyFieldsRecursive = (payload: any, seen = new WeakSet()): any => {
  if (payload === null || payload === undefined) {
    return null;
  }

  // Handle Date objects
  if (payload instanceof Date) {
    return payload;
  }

  // Handle arrays
  if (Array.isArray(payload)) {
    const filteredArray = payload
      .map((item) => deleteNullOrEmptyFieldsRecursive(item, seen))
      .filter((item) => item !== null && item !== undefined && item !== '');
    return filteredArray.length ? filteredArray : null;
  }

  // Handle objects
  if (typeof payload === 'object') {
    // Handle circular references
    if (seen.has(payload)) {
      return payload;
    }
    seen.add(payload);

    const cleanObject = Object.entries(payload).reduce((acc, [key, value]) => {
      const cleanValue = deleteNullOrEmptyFieldsRecursive(value, seen);
      if (cleanValue !== null && cleanValue !== undefined && cleanValue !== '') {
        acc[key] = cleanValue;
      }
      return acc;
    }, {});

    return Object.keys(cleanObject).length ? cleanObject : null;
  }

  // Handle primitive values
  return payload;
};

/* Deletes null/undefined/empty string fields*/
export const deleteNullOrEmptyFields = (payload: object) => {
  for (const key of Object.keys(payload)) {
    if (payload[key] == null || payload[key] === '') {
      delete payload[key];
    }
  }
  return payload;
};

export const deleteNullFields = (payload: object) => {
  for (const key of Object.keys(payload)) {
    if (payload[key] === null) delete payload[key];
  }
};

export const getApiResponseDataRange = (res: AxiosResponse | string) => {
  try {
    const range = typeof res === 'string' ? res : (res.headers['content-range'] as string);
    // TODO: sometimes api returns range with negative number 0--1/0
    const match = range.match(/.+(\d+)-(-?\d+)\/(\d+)/);
    return {
      range: [parseInt(match[1], 10), parseInt(match[2], 10)] as const,
      total: match && parseInt(match[3], 10),
    } as ApiResponseDataRange;
  } catch (error) {
    return null;
  }
};

export function chunk<T>(array: T[], size: number) {
  const chunkedArray: T[][] = [];
  for (let i = 0; i < array.length; i++) {
    const last = chunkedArray.at(-1);
    if (!last || last.length === size) {
      chunkedArray.push([array[i]]);
    } else {
      last.push(array[i]);
    }
  }
  return chunkedArray;
}

export function compressFile(file: File): Promise<Uint8Array> {
  return import('fflate').then(async ({gzip}) => {
    const fileBuffer = new Uint8Array(await file.arrayBuffer());
    return new Promise((resolve, reject) => {
      gzip(fileBuffer, (err, res) => {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
  });
}

export const getFormattedCurrency = (amount: number, currency: string) => {
  return `${new Intl.NumberFormat('en-EN', {
    currency: currency,
    style: 'currency',
  }).format(amount / 100)}`;
};

export const generatePathWithQueryParams = (
  pattern: string,
  params: Record<string, string>,
  query: Record<string, unknown>,
) => {
  const generatedPath = generatePath(pattern, params);
  const queryString = Object.keys(query)
    .filter((key) => query[key] !== undefined)
    .map((key) => `${key}=${query[key]}`)
    .join('&');
  return `${generatedPath}?${queryString}`;
};

export const getLocalizedLink = (link: string, locale = 'en') => {
  const supportedLocales = ['en', 'ja', 'es', 'pt'];
  if (!supportedLocales.includes(locale)) {
    locale = 'en';
  }
  if (link) {
    const parsedUrl = new URL(link);
    if (locale !== 'en') {
      parsedUrl.pathname = `/${locale}${parsedUrl.pathname}`;
    }
    return parsedUrl.toString();
  }
  return link;
};

export const sortAlphabetically = <T>(items: T[], sortField: keyof {[x: string]: T}): T[] => {
  return items?.sort((a, b) => a[sortField].localeCompare(b[sortField]));
};

export const getConfirmSaveChangesPayload = (t: TFunction) => ({
  description: t('common:confirm.ask_save_changes.description', 'Do you want to save your changes?'),
  acceptButton: t('common:confirm.ask_save_changes.accept', 'Save'),
  cancelButton: t('common:confirm.ask_save_changes.reject', "Don't Save"),
});

export const trimWhiteSpace = (s: string) => s.trim().replace(/\s+/g, ' ');
