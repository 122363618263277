import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';

import {TaskImportTotals} from '../utils/types';

const defaultLegendLabels = {all: 'Activities', new: 'new', edited: 'edited', error: 'errors'} as const;
const defaultLegendIcons = {new: 'add-plus', error: 'error', edited: 'swap_horizontal'} as const;
const legendClasses: {[key in keyof TaskImportTotals]?: string} = {
  new: 'legend-compare__item--new',
  edited: 'legend-compare__item--edit',
  error: 'legend-compare__item--light',
};
type LegendLabelGetter = (type: keyof TaskImportTotals, value?: number) => string;
type LegendIconGetter = LegendLabelGetter;

const defaultLegendLabelGetter: LegendIconGetter = (type) => {
  return defaultLegendLabels[type];
};
const defaultLegendIconGetter: LegendIconGetter = (type) => {
  return defaultLegendIcons[type];
};

type LegendCompareProps = {
  className: string;
  totals: Partial<TaskImportTotals>;
  getLegendLabel?: LegendLabelGetter;
  getLegendIcon?: LegendIconGetter;
  showErrors?: boolean;
  toggleErrors?: () => void;
};

const LegendCompare: FC<LegendCompareProps> = ({
  className,
  totals,
  getLegendIcon = defaultLegendIconGetter,
  getLegendLabel = defaultLegendLabelGetter,
  showErrors,
  toggleErrors,
}) => {
  const keys: (keyof TaskImportTotals)[] = ['new', 'error', 'edited'];
  const {t} = useTranslation('import');
  const legendTranslations = {
    all: t('legend.text.all', getLegendLabel('all')),
    new: t('legend.text.new', getLegendLabel('new')),
    edited: t('legend.text.edited', getLegendLabel('edited')),
    error: t('legend.text.error', getLegendLabel('error')),
    unChanged: t('legend.text.unChanged', 'unchanged'),
  };

  return (
    <div className={`legend-compare ${className}`}>
      <div className={`legend-compare__item ${legendClasses['all']}`}>
        {getLegendIcon('all') ? <Icon colorFill name={getLegendIcon('all')} className="legend-compare__icon" /> : null}
        <span className="legend-compare__text">
          {totals.unChanged + totals.edited + totals.new} {t('legend.text.all')}
        </span>
      </div>
      {keys.map((key) => {
        const hasErrors = key === 'error' && !!toggleErrors;
        const legendClass = `legend-compare__item ${legendClasses[key]}`;
        const icon = getLegendIcon(key) ? (
          <Icon colorFill name={getLegendIcon(key)} className="legend-compare__icon" />
        ) : null;
        const total = key === defaultLegendLabels.new ? totals.new : totals[key];
        const labelText = `${total} ${legendTranslations[key]}`;
        const buttonText = hasErrors
          ? `${
              showErrors
                ? t('legend.errors.showAll', 'Show all rows')
                : `${t('legend.errors.showErrors', 'Show')} ${labelText}`
            }`
          : labelText;

        return hasErrors ? (
          <Tooltip key={key} text={`${labelText} ${t('legend.tooltip.willNotImport')}`}>
            <Button className={legendClass} colorStyle="danger" onClick={toggleErrors} style={{whiteSpace: 'normal'}}>
              {icon}
              <span className="legend-compare__text">{buttonText}</span>
            </Button>
          </Tooltip>
        ) : (
          <div key={key} className={legendClass}>
            {icon}
            <span className="legend-compare__text">{labelText}</span>
          </div>
        );
      })}
    </div>
  );
};
export default LegendCompare;
