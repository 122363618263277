import {PDFUI} from '@foxitsoftware/foxit-pdf-sdk-for-web-library/lib/UIExtension.full';
import * as i18next from 'i18next';

import usTranslations from '../../../../../../public/locales/en/map_view.json';
import spanishTranslations from '../../../../../../public/locales/es/map_view.json';
import japaneseTranslations from '../../../../../../public/locales/ja/map_view.json';

export const languageMap = {
  en: 'en-US',
  es: 'es-419',
  ja: 'ja-JP',
  pt: 'en-US', // missed
};

const translationMap = {
  'en-US': usTranslations,
  'ja-JP': japaneseTranslations,
  'es-419': spanishTranslations,
} as const;

/**
 * ! Do not delete. Needed for BabelEdit translations
 * * Add more translations as needed
 * t("map_view:toolbar.tooltip.refreshView.title")
 * t("map_view:toolbar.tooltip.zoomToSaved.title")
 * t("map_view:toolbar.tooltip.addDrawing.title")
 * t("map_view:toolbar.tooltip.newSnapshot.title")
 * t("map_view:toolbar.tooltip.savedSnapshots.title")
 * t('map_view:toolbar.tooltip.downloadSnapshot.title')
 * t('map_view:toolbar.tooltip.deleteSnapshot.title')
 * t('map_view:toolbar.tooltip.copyToClipBoard.title')
 */

export const applyI18nOverrides = async (ui: PDFUI) => {
  await ui.waitForInitialization();
  // Grumble grumble Foxit index.d.ts sketchy.
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const i18n = ui.i18n as any as i18next.i18n;
  Object.keys(translationMap).forEach((lang) => {
    i18n.addResourceBundle(lang, 'ui_', translationMap[lang], true, true);
  });
  const root = await ui.getRootComponent();
  root.localize();
  ui.redraw();
};
