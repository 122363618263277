import cn from 'classnames';
import {FC, MouseEvent} from 'react';

import Button from 'shared/components/Button';

import {ActionButtonConfig} from '../useConfirm/state';

import s from './ActionButton.module.scss';

interface Props extends ActionButtonConfig {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  dataCy: string;
  className?: string;
}

const ActionButton: FC<Props> = ({title, onClick, isDisabled, className, dataCy, type = 'default'}: Props) => {
  return (
    <Button
      className={cn(className, {
        [s['action-button--danger']]: type === 'danger',
        [s['action-button--success']]: type === 'success',
      })}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      disabled={isDisabled}
      data-cy={dataCy}
    >
      {title}
    </Button>
  );
};
export default ActionButton;
