/* eslint-disable @typescript-eslint/naming-convention */
export default {
  NODE_ENV: process.env.NODE_ENV,
  API_URL: process.env.REACT_APP_API_URL,
  API_URL_V2: process.env.REACT_APP_API_URL_V2,
  reactAppEnv: process.env.REACT_APP_ENV as 'qa' | 'staging' | 'production' | 'develop' | 'local',
  cypress: !!window.Cypress,
  social: {
    pixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    zoominfoId: process.env.REACT_APP_ZOOMINFO_ID,
    facebookDomainVerification: process.env.REACT_APP_FACEBOOK_DOMAIN_VERIFICATION,
  },
  byCoreBaseLink: process.env.REACT_APP_BYCORE_BASE_LINK,
  downloadAppLinkPage: process.env.REACT_APP_DOWNLOAD_LINK,
  appleStoreAppID: process.env.REACT_APP_APPLE_STORE_APP_ID,
  hubspotMeeting: process.env.REACT_APP_HUBSPOT_MEETING_LINK,
  mobileAppLinks: {
    appStore: process.env.REACT_APP_APPLE_STORE_LINK,
    googlePlay: process.env.REACT_APP_GOOGLE_PLAY_LINK,
  },
  legalPages: {
    termsOfService: process.env.REACT_APP_TERMS_LINK,
    privacyPolicy: process.env.REACT_APP_PRIVACY_POLICY_LINK,
    aboutCore: process.env.REACT_APP_ABOUT_CORE_LINK,
    contactUs: process.env.REACT_APP_CONTACT_US_LINK,
    downloadApp: process.env.REACT_APP_DOWNLOAD_LINK,
  },
  corePricing: process.env.REACT_APP_CORE_PRICING_LINK,
  corePricingHelpfulVideos: process.env.REACT_APP_CORE_PRICING_HELPFUL_VIDEOS_LINK,
  weatherApiKey: process.env.REACT_APP_WEATHER_API_KEY,
  webChatClient: process.env.REACT_WEB_CHAT_CLIENT,
};
