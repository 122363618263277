import dayjs from 'dayjs';
import {camelizeKeys} from 'humps';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import TasksApi from 'api/tasks';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {SortOrder} from 'shared/constants/common';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {fetchAllWithGenerator} from 'shared/helpers/axios'; // Importing the new fetch function
import {IssueModelDTO, IssueModelRawDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

const filterClosedStatus = (issue: IssueModelDTO) => issue.status !== TaskStatusType.closed;

export function useFetchDailiesIssues() {
  const {projectId} = useParams<{projectId: string}>();
  const {queryParams} = useFilterContext();

  const selectedDay = dayjs(queryParams.schedEndFirst).toISOString();
  const queryKey = [QUERY_CACHE_KEYS.dailiesIssues, projectId, selectedDay, JSON.stringify(queryParams)];

  const fetcher = async () => {
    return fetchAllWithGenerator<IssueModelRawDTO>({
      request: (offset, limit) =>
        TasksApi.getTaskIssues(projectId, {
          offset,
          limit,
          sortParams: {
            sortField: 'description',
            sortOrder: SortOrder.DESC,
          },
          filterParams: {
            ...queryParams,
          },
        }),
      initialTake: 500,
    });
  };

  const {data: res, isLoading} = useQuery(queryKey, fetcher, {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5, // 5 minutes
    cacheTime: 1000 * 60 * 10, // 10 minutes
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const issues = res ? (camelizeKeys(res) as IssueModelDTO[]).filter(filterClosedStatus) : [];
  return {issues, isLoading};
}
